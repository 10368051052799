.about {
  padding: var(--sectionPadd) 0;
}
.title_bar {
  display: flex;
  justify-content: start;
  align-items: flex-start;
  padding: 20px 0;
}
.title_bar .title {
  color: var(--blue);
  font-weight: 600;
  margin: 0;
  padding-left: 5px;
}
.title_bar .line {
  background-color: var(--blue);
  display: block;
  width: 100px;
  height: 3px;
}
.sub_title {
  font-weight: 700;
  color: var(--font-dark);
}
.about p {
  text-align: justify;
  color: var(--font-light);
  font-weight: 600;
  margin: 10px 0;
  font-size: 1.2rem;
}
.about .box {
  height: 210px;
  margin-top: 30px;
  border-radius: 10px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
  background-color: rgba(254, 210, 170, 1);
}
.about .box.r {
  background-color: #65c18c;
}
.about .box div {
  padding: 20px 10px 10px 30px;
  display: block;
}
.about .box span {
  color: var(--orange);
  font-size: 3.3rem;
  position: relative;
  z-index: 1;
}
.about .box.r span {
  color: var(--green);
}
.about .box span::after {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background-color: #ff7f3f;
  position: absolute;
  top: -10px;
  left: -15px;
  border-radius: 50%;
  padding: 0 30px;
  z-index: -1;
  opacity: 0.7;
}
.about .box.r span::after {
  background-color: var(--green);
  opacity: 0.3;
}
.about .box h5 {
  font-weight: 700;
  color: var(--font-dark);
  display: block;
  font-size: 1.2rem;
  padding: 0 15px;
}

/* mobile */

.about.sm.container {
  padding: 0;
  overflow-x: hidden;
}
.about.sm .box_content {
  --bs-gutter-x: 0 !important;
}
.about.sm .box h5 {
  font-size: 0.9rem;
  padding: 0 10px;
}
.about.sm .box {
  box-shadow: 0 0 0;
  margin: 3px;
}
.about.sm .box {
  box-shadow: 0 0 0;
  margin: 3px;
}
